import axios from "axios";
import Cookies from "js-cookie";

//Ruta de la api de autorizacion Strapi
const ENDPOINT_PATH = process.env.VUE_APP_RUTA_API+"/auth/local";



export default {

    setUserLogged(userLogged) {
      //console.log("aqui es setUserLogged:",userLogged )
      Cookies.set("userLogged", JSON.stringify(userLogged));     
    //Cookies.set("userLogged", userLogged);
    },

     getUserLogged() {
      //console.log("esta es la cookie:",Cookies.get('userLogged'))

     //return JSON.parse(Cookies.get('userLogged'));    
       if (JSON.stringify(Cookies.get('userLogged')) == undefined){
        //console.log("entrooooo")
        return JSON.stringify(Cookies.get('userLogged'));
      }else{
        //console.log("por el else")
        //return Cookies.get('userLogged')
       // return Cookies(userLogged);
       return JSON.parse(Cookies.get('userLogged')) 
      } 
      
    }, 



    deleteUserLogged() {
    Cookies.remove('userLogged');
    },

    async register(username,email, password,CodigoHabilitacion) {
    //console.log("Hola ya llego",username,email, password,CodigoHabilitacion);
    const user = {username,email, password,CodigoHabilitacion };
     return await axios.post(ENDPOINT_PATH + "/register", user)
    .then(response => {
/*       console.log(response);
      console.log("Registro correcto") */
      //this.ExisteCodigo = 1;
    })
    .catch(error => {
      // Handle error.  
      //console.log(response);
      this.ExisteCodigo = 0;
      console.log('An error occurred:', error.response);
    });     
    },


  async login(identifier, password) {
    console.log('User profile')
    const user = {identifier, password};
    return await axios.post(ENDPOINT_PATH , user)
    .then(response => {
      // Handle success.
       
     /* console.log('End Point',axios.post(ENDPOINT_PATH , user));
      console.log('User profile', response.data.user.CodigoHabilitacion);
      console.log('User token', response.data.jwt); 
      console.log('User res', response); 
      */  
      
      return response;
    })
    .catch(error => {
      // Handle error.
      console.log('An error occurred:', error.response);
    });
  },


  
  
};