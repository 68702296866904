<template>
  <div>
    <v-select v-model="selectedFilter" @change="onChange($event)" :items="filters" label="Filtrar por" outlined></v-select>
    <!-- <v-date-picker v-model="selectedDate" label="Seleccionar fecha" outlined></v-date-picker> -->

<!--        <v-col cols="auto">
      <v-btn  @click="getData" outlined elevation="8" size="x-large">Actualizar</v-btn>
    </v-col> -->

    <hr/>

    <v-data-table :items-per-page="10" :headers="headers" :items="filteredData" :search="search" ></v-data-table>
          
  </div>
</template>



<script>
import jsonData from '@/Data/data.json';
import auth from "@/auth";
import axios from "axios";


export default {
  data() {
    return {
      selectedFilter: 'Personas',
      selectedDate: null,
      filters: ['Personas', 'Empresas', 'Ofertas'],
      headers: [
        { text: 'PERIODO', value: 'month' },
        { text: 'CANTIDAD POR PERIODO', value: 'count' }
      ],
      search: '',
      data: jsonData,
      DatosFiltros:[]
    };
  },


   beforeMount() { 
    this.getData()     
  },


  computed: {

   filteredData() 
       {
        // const data = this.getDataByFilter();
        //this.getData()             
          return this.getMonthlyCount();            
      }, 

      userLogged() 
      {
          return auth.getUserLogged();
      },
  },
  methods: {

    onChange(event) {
         // console.log(event.target.values)
          this.getData()
      },

    getMonthlyCount() {
      const monthlyCount = {};
      this.DatosFiltros.forEach(item => {
        const date = new Date(item.created_at);
        const month = `Mes: ${date.getMonth() + 1} => Año: ${date.getFullYear()}`;
        if (monthlyCount[month]) {
          monthlyCount[month] += 1;
        } else {
          monthlyCount[month] = 1;
        }
      });

      return Object.keys(monthlyCount).map(month => ({
        month,
        count: monthlyCount[month] +' '+ this.selectedFilter
      }));
    },


      async getData(){
      let url = ""

      if(this.selectedFilter === 'Personas') 
      {
          url = process.env.VUE_APP_RUTA_API +"/t-personas"
      }
      if(this.selectedFilter === 'Empresas') 
      {
          url = process.env.VUE_APP_RUTA_API +"/empresas"
      }
      if(this.selectedFilter === 'Ofertas') 
      {
          url = process.env.VUE_APP_RUTA_API+"/ofertas"
      }

      try {
       //Se realiza la constulta al Api Rest dependiendo de la opcion seleccionada en la lista
       const Key = 'Bearer ' + auth.getUserLogged().token;
      let response = await axios.get(url,{
          headers: {
          Authorization: Key
          } 
      })

      if(response.data)
      {            
          this.DatosFiltros = response.data   
          //console.log("this.DatosFiltros:",this.DatosFiltros)                
      }           
      } catch (error) {
          console.log("se presento el siguiente error:",error )
      }

      
       
    }



  }
};
</script>

