<template>
  
  <v-layout>
    <v-flex>
      <v-card
        v-if="TipoUsuario === 'Administrador'"
        class="mx-auto"
        max-width="280"
      >
        <v-img
          class="white--text align-end"
          height="150px"
          src="../assets/Administrator.jpg"
        >
        </v-img>

        <v-card-title>Administración App</v-card-title>

        <v-card-subtitle> Opciones para administrar el sitio </v-card-subtitle>

        <v-card-actions>
          <v-flex>
            <v-row align="center" justify="center">
              <v-col class="text-center" cols="11">
                <v-btn v-on:click="AtcionAdmin(1)" small block
                  >Cargar archivo Rethus</v-btn
                >
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col class="text-center" cols="11">
                <v-btn v-on:click="AtcionAdmin(2)" small block
                  >Consulta Rethus</v-btn
                >
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col class="text-center" cols="11">
                <v-btn v-on:click="AtcionAdmin(3)" small block
                  >Estadisticas
                </v-btn>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col class="text-center" cols="11">
                    <v-btn v-on:click="AtcionAdmin(4)" small block>Verificaciones</v-btn>
                </v-col>                  
            </v-row>             
            <!--                 <v-row align="center" justify="center">
                    <v-col class="text-center" cols="11">
                    <v-btn v-on:click="AtcionAdmin(4)" small block>Consulta Candidatos </v-btn>
                    </v-col>                  
                </v-row>   -->
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-flex>

    <v-flex v-if="TipoUsuario === 'Administrador' && ValorMenuir === 0">
      <v-row>
        <v-col cols="12" md="12">
          <v-row>
            <v-col>
              <v-alert border="top" colored-border type="warning" elevation="2">
                SELECCIONE UNA OPCIÓN DEL MENÚ LATERAL IZQUIERDO PARA COMENZAR
              </v-alert>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-flex>

    <v-flex v-if="TipoUsuario === 'Administrador' && ValorMenuir === 1">
      <v-sheet
        border="lg opacity-12"
        max-width="820"
        color="#F5F5F5"
        class="d-flex align-center justify-center mx-auto flex-wrap text-center"
      >
        <v-container fluid>
          <v-row>
            <div
              class="d-flex align-center justify-center flex-wrap text-center mx-auto px-4 text-h5 font-weight-medium mb-2"
            >
              Formulario cargue de profesionales - Rethus
            </div>

            <v-col cols="12" md="12">
              <v-alert
                v-if="this.enviado && !$v.filesRethus.required"
                dense
                outlined
                type="error"
              >
                Por favor seleccione un archivo para ser cargado
              </v-alert>

              <v-file-input
                v-model="filesRethus"
                required
                label="Seleccione el archivo a cargar"
                variant="outlined"
                class="align-center justify-center mx-auto text-center"
              >
              </v-file-input>

              <v-row>
                <v-col>
                  <v-btn
                    block
                    class="text-none"
                    color="success"
                    variant="flat"
                    accept=".xlsx"
                    @click="UploadFileRethus()"
                  >
                    <span class="hidden-sm-and-down">
                      VALIDAR Y CARGAR EL ARCHIVO
                    </span>
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    block
                    class="text-none"
                    color="info"
                    variant="flat"
                    accept=".xlsx"
                    @click="CleanFileRethus()"
                  >
                    <span class="hidden-sm-and-down"> LIMPIAR </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <br />

          <!-- alerta de error de archivo cargado  -->
          <v-alert v-if="this.error > 0" dense outlined type="error">
            Archivo no cargado, revise los errores mostrados en la tabla
          </v-alert>

          <!-- alerta de los archivos cargados exitosamente  -->
          <v-alert v-if="this.error === 0" dense text type="success">
            Archivo cargado correctamente
          </v-alert>

          <v-card v-if="this.error > 0">
            <v-card-title>
              ERRORES ENCONTRADOS DURANTE LA CARGA DE INFORMACIÓN
              <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table
              :headers="cabecera"
              :items="Errores"
              loading-text="Cargando... Por favor espere"
            >
            </v-data-table>
          </v-card>
        </v-container>
      </v-sheet>
    </v-flex>

    <v-flex v-if="TipoUsuario === 'Administrador' && ValorMenuir === 2">
      <v-sheet
        border="lg opacity-12"
        max-width="820"
        color="#F5F5F5"
        class="d-flex align-center justify-center mx-auto flex-wrap text-center"
      >
        <v-container fluid>
          <v-row>
            <div
              class="d-flex align-center justify-center flex-wrap text-center mx-auto px-4 text-h5 font-weight-medium mb-2"
            >
              Administración profesionales - Rethus
            </div>

            <v-col cols="12" md="12">
              <v-row>
                <v-col>
                  <ConsultaRethus />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <br />

          <!-- alerta de error de archivo cargado  -->
          <v-alert v-if="this.error > 0" dense outlined type="error">
            Archivo no cargado, revise los errores mostrados en la tabla
          </v-alert>

          <!-- alerta de los archivos cargados exitosamente  -->
          <v-alert v-if="this.error === 0" dense text type="success">
            Archivo cargado correctamente
          </v-alert>

          <v-card v-if="this.error > 0">
            <v-card-title>
              ERRORES ENCONTRADOS DURANTE LA CARGA DE INFORMACIÓN
              <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table
              :headers="cabecera"
              :items="Errores"
              loading-text="Cargando... Por favor espere"
            >
            </v-data-table>
          </v-card>
        </v-container>
      </v-sheet>

      <v-sheet />
    </v-flex>

    <v-flex v-if="TipoUsuario === 'Administrador' && ValorMenuir === 3">
      <v-sheet
        border="lg opacity-12"
        max-width="820"
        color="#F5F5F5"
        class="d-flex align-center justify-center mx-auto flex-wrap text-center"
      >
        <v-container fluid>
          <v-row>
            <div
              class="d-flex align-center justify-center flex-wrap text-center mx-auto px-4 text-h5 font-weight-medium mb-2"
            >
              Consultas generales estadíticas
            </div>

            <v-col cols="12" md="12">
              <v-row>
                <v-col>
                  <Estadisticas />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <br />

          <!-- alerta de error de archivo cargado  -->
          <v-alert v-if="this.error > 0" dense outlined type="error">
            Archivo no cargado, revise los errores mostrados en la tabla
          </v-alert>

          <!-- alerta de los archivos cargados exitosamente  -->
          <v-alert v-if="this.error === 0" dense text type="success">
            Archivo cargado correctamente
          </v-alert>

          <v-card v-if="this.error > 0">
            <v-card-title>
              ERRORES ENCONTRADOS DURANTE LA CARGA DE INFORMACIÓN
              <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table
              :headers="cabecera"
              :items="Errores"
              loading-text="Cargando... Por favor espere"
            >
            </v-data-table>
          </v-card>
        </v-container>
      </v-sheet>

      <v-sheet />
    </v-flex>

    <v-flex v-if="TipoUsuario === 'Administrador' && ValorMenuir === 4">
      <v-sheet
        border="lg opacity-12"
        max-width="820"
        color="#F5F5F5"
        class="d-flex align-center justify-center mx-auto flex-wrap text-center"
      >
        <v-container fluid>
          <v-row>
            <div
              class="d-flex align-center justify-center flex-wrap text-center mx-auto px-4 text-h5 font-weight-medium mb-2"
            >
              Verificaciones
            </div>

            <v-col cols="12" md="12">
              <v-row>
                <v-col>
                  <Verificaciones/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <br />

        </v-container>
      </v-sheet>

      <v-sheet />
    </v-flex>    


  </v-layout>
</template>

<script>
import store from "../store/index.js";
import { mapMutations, mapState } from "vuex";
import auth from "@/auth";
import readXlsFile from "read-excel-file";
import Vue from "vue";
import Vuelidate from "vuelidate";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import ConsultaRethus from "../components/ConsultaRethus.vue";
import Estadisticas from "../components/Estadisticas.vue";
import Verificaciones from '../components/Verificaciones.vue'

Vue.use(Vuelidate);


export default {
  name: "Home",

  data: () => ({
    items: [],
    filesRethus: [],
    cabecera: [
      { text: "CONSECUTIVO", value: "Consecutivo" },
      { text: "COLUMNA ERROR", value: "Columna" },
      { text: "DESCRIPCION ERROR", value: "Descripcion" },
      { text: "VALOR ERRADO", value: "Valor" },
      { text: "EJEMPLO ", value: "Ejemplo" },
    ],
    enviado: false,
    error: "",
    Errores: [],
    ValorMenuir: 0,
  }),

  validations: {
    filesRethus: { required },
  },

  components: {
    ConsultaRethus,
    Estadisticas,
    Verificaciones
  },

  methods: {
    async InsertarRegistro(Profesional) {
      const Key = "Bearer " + auth.getUserLogged().token;
      //console.log("Profesional:",Profesional)
      try {
        const response = await axios.post(
          "http://159.223.183.57:1337/rethuses",
          Profesional,
          {
            headers: { Authorization: Key },
          }
        );
        //console.log("ok -> " + response.data);
        return response.data;
      } catch (err) {
        if (err.response.status === 500) {
          this.ActualizarRegistro(Profesional, Key);
        }
      }
    },

    async ActualizarRegistro(Profesional, Keys) {
      //TODO: consultar si la persona existe
      try {
        await axios
          .get(
            "http://159.223.183.57:1337/rethuses?NroId=" +
              Profesional.NroId.toString() +
              "&&TipoId=" +
              Profesional.TipoId.toString(),
            {
              headers: {
                Authorization: Keys,
              },
            }
          )
          .then(async (response) => {
            var id = response.data[0].id;

            try {
              const response = await axios.put(
                "http://159.223.183.57:1337/rethuses/" + id,
                Profesional,
                {
                  headers: { Authorization: Keys },
                }
              );
              //console.log("ok -> " + response.data);
              return response.data;
            } catch (err) {
              console.log(err);
            }
          });
      } catch (error) {
        console.log("La persona no existe y debe ser ingresada", error);
      } //Fin catch

      const Key = "Bearer " + auth.getUserLogged().token;
      //console.log("Profesional:",Profesional)
    },

    UploadFile() {
      alert("mensaje");
    },

    UploadFileRethus() {
      this.enviado = true;
      const Er_TipoId = /^[a-zA-Z]{2}$/;
      const Er_NroId = /^[0-9]{1,15}$/;
      const Er_PNombrePapellido = /^[a-zA-ZáéíóúÁÉÍÓ ]{1,25}$/;
      const Er_SNombreSapellido = /^[a-zA-ZáéíóúÁÉÍÓÚ ]{0,25}$/;

      if (this.filesRethus !== "") {
        readXlsFile(this.filesRethus).then((rows) => {
          this.items = rows.filter((row, indice) => indice > 0);

          //Procesar el archivo
          for (let i = 0; i < this.items.length; i++) {
            if (Er_TipoId.exec(this.items[i][1]) === null) {
              var Consecutivo = this.items[i][0];
              var Columna = "Tipo Identificación";
              var Descripcion =
                "Se permiten exactamente 2 letras, no números, no espacios, no caracteres especiales";
              var Valor = this.items[i][1];
              var Ejemplo = "CC , CE, PA";
              this.Errores.push({
                Consecutivo,
                Columna,
                Descripcion,
                Valor,
                Ejemplo,
              });
              this.error = this.error + 1;
            }

            if (Er_NroId.exec(this.items[i][2]) === null) {
              var Consecutivo = this.items[i][0];
              var Columna = "Número Identificación";
              var Descripcion =
                "Tiene letras, caracteres ilegales o supera 15 caracteres";
              var Valor = this.items[i][2];
              var Ejemplo = "99123456";
              this.Errores.push({
                Consecutivo,
                Columna,
                Descripcion,
                Valor,
                Ejemplo,
              });
              this.error = this.error + 1;
            }

            if (Er_PNombrePapellido.exec(this.items[i][3]) === null) {
              var Consecutivo = this.items[i][0];
              var Columna = "Primer Apellido";
              var Descripcion =
                "Tiene números, caracteres ilegales o supera 25 caracteres";
              var Valor = this.items[i][3];
              var Ejemplo = "Pérez";
              this.Errores.push({
                Consecutivo,
                Columna,
                Descripcion,
                Valor,
                Ejemplo,
              });
              this.error = this.error + 1;
            }

            if (Er_SNombreSapellido.exec(this.items[i][4]) === null) {
              var Consecutivo = this.items[i][0];
              var Columna = "Segundo Apellido";
              var Descripcion =
                "Tiene números, caracteres ilegales o supera 25 caracteres";
              var Valor = this.items[i][4];
              var Ejemplo = "Pérez";
              this.Errores.push({
                Consecutivo,
                Columna,
                Descripcion,
                Valor,
                Ejemplo,
              });
              this.error = this.error + 1;
            }

            if (Er_PNombrePapellido.exec(this.items[i][5]) === null) {
              var Consecutivo = this.items[i][0];
              var Columna = "Primer Nombre";
              var Descripcion =
                "Tiene números, caracteres ilegales o supera 25 caracteres";
              var Valor = this.items[i][5];
              var Ejemplo = "Castillo";
              this.Errores.push({
                Consecutivo,
                Columna,
                Descripcion,
                Valor,
                Ejemplo,
              });
              this.error = this.error + 1;
            }

            if (Er_SNombreSapellido.exec(this.items[i][6]) === null) {
              var Consecutivo = this.items[i][0];
              var Columna = "Segundo Nombre";
              var Descripcion =
                "Tiene números, caracteres ilegales o supera 25 caracteres";
              var Valor = this.items[i][6];
              var Ejemplo = "GoméZ";
              this.Errores.push({
                Consecutivo,
                Columna,
                Descripcion,
                Valor,
                Ejemplo,
              });
              this.error = this.error + 1;
            }

            if (this.error.length === 0) {
              this.error = 0;
            }
          }

          if (this.error === 0) {
            //Guardar en la tabla historica
            for (let i = 0; i < this.items.length; i++) {
              const Profesional = {
                TipoId: this.items[i][1],
                NroId: this.items[i][2].toString(),
                PrimerApellido: this.items[i][3],
                SegundoApellido: this.items[i][4],
                PrimerNombre: this.items[i][5],
                SegundoNombre: this.items[i][6],
              };

              this.InsertarRegistro(Profesional);
            }
          }
        });
      }
    },

    CleanFileRethus() {
      this.filesRethus = "";
      this.items = [];
      this.error = "";
      this.enviado = false;
    },

    AtcionAdmin(ValorMenu) {
      this.ValorMenuir = ValorMenu;
    },
  },

  computed: {
    userLogged() {
      return auth.getUserLogged();
    },

    
    ...mapState(["VarLogin", "TipoUsuario"]),
  },
};
</script>
