<template>

      <v-card
        elevation="1"
        color="#E8EAF6"
        height="100%"
      >

    <v-container grid-list-md  >  

    <v-carousel>
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :src="item.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>


        <v-card elevation="24" outlined shaped tile color="orange lighten-2"></v-card> 
           <v-layout row wrap mt-8 >
         
          <v-flex>
             <FormAdministrator/>        
          </v-flex> 

          <v-flex>
<!--             hola {{ TipoUsuario }} -->
                <v-card 
                   v-if="TipoUsuario === 'Persona' || TipoUsuario === ''"
                  class="mx-auto"
                  max-width="280"
                >
                  <v-img
                    class="white--text align-end"
                    height="150px"
                    src="../assets/personas.jpg"
                  >
                  </v-img>
                  <v-card-title>Ingresa tu hoja de vida</v-card-title>

                  <v-card-subtitle>
                    Ingresa o actualiza tu hoja de vida y encuentra las mejores ofertas 
                  </v-card-subtitle>

                  <v-card-actions>
                    <v-btn :to="{name:'hojadevida'}" color="orange lighten-2" text>
                      Ingresa tu hoja de vida
                    </v-btn>
                  </v-card-actions>
                </v-card>
          </v-flex>  

          <v-flex>
            <v-card
              v-if="TipoUsuario == 'Empresa' || TipoUsuario == '' "
              class="mx-auto"
              max-width="280"
            >
              <v-img
                class="white--text align-end"
                height="150px"
                src="../assets/Reclutadores.jpg"
              >
              </v-img>
              <v-card-title>Registre su empresa</v-card-title>

              <v-card-subtitle>
                Registrese como empresa y comparta ofertas con la comunidad 
              </v-card-subtitle>

              <v-card-actions>
                <v-btn :to="{name:'hojadevidareclutador'}" color="orange lighten-2" text>
                  Registre su empresa
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>           

          <v-flex>
              <v-card
                v-if="TipoUsuario == 'Persona' || TipoUsuario == ''"
                class="mx-auto"
                max-width="280"
              >
                <v-img
                  class="white--text align-end"
                  height="150px"
                  src="../assets/Ofertas_banner.jpg"
                >
                </v-img>
                <v-card-title>Consulta de Ofertas</v-card-title>

                <v-card-subtitle>
                  Consulta las ofertas laborales y educativas publicadas
                </v-card-subtitle>

                <v-card-actions>
                  <v-btn :to="{name:'filtroOfertas'}" color="orange lighten-2" text>
                    Ver Ofertas
                  </v-btn>
                </v-card-actions>
              </v-card>
          </v-flex>  

          <v-flex>
              <v-card
              v-if="TipoUsuario == 'Empresa' || TipoUsuario == '' "
                class="mx-auto"
                max-width="280"
              >
                <v-img
                  class="white--text align-end"
                  height="150px"
                  src="../assets/Ofertas.jpg"
                >
                </v-img>
                <v-card-title>Publicar Ofertas</v-card-title>

                <v-card-subtitle>
                  Ingresa y publica tus ofertas laborales o educativas 
                </v-card-subtitle>

                <v-card-actions>
                  <v-btn :to="{name:'ofertaslaborales'}" color="orange lighten-2" text>
                    Consultar - publicar oferta
                  </v-btn>
                </v-card-actions>
              </v-card>
          </v-flex>  

    <!--       <v-flex>
              <v-card
                class="mx-auto"
                max-width="280"
                v-if="TipoUsuario !== 'Administrador' "
              >
                <v-img
                  class="white--text align-end"
                  height="150px"
                  src="../assets/News.jpg"
                >
                </v-img>
                <v-card-title>Noticias</v-card-title>

                <v-card-subtitle>
                  Mantente informado acerca de las principales noticias del colegio    
                </v-card-subtitle>

                <v-card-actions>
                  <v-btn href="https://cnbcolombia.org/" target="_blank" color="orange lighten-2" text>
                    Ver mas ..
                  </v-btn>
                </v-card-actions>
              </v-card>
          </v-flex>  -->

          </v-layout>

     
    </v-container>
 </v-card>
 
</template>

<script>
import store from "../store/index.js";
import {mapMutations, mapState} from 'vuex';
import auth from "@/auth";
import readXlsFile from "read-excel-file";
import Vue from "vue";
import Vuelidate from "vuelidate";
import {required} from 'vuelidate/lib/validators';
import FormAdministrator from '../components/FormAdministrator.vue'

Vue.use(Vuelidate);

  export default {
    name: 'Home',
  
    data: () => ({
      show: false,
      model: 0,
      Admin_CargaArchivo: 1,
      Admin_Verificacion: 0,
      Admin_ConsultaCandidato: 0,
      items:[],
      filesRethus: [],
       cabecera: [
        {text:"CONSECUTIVO", value:'Consecutivo'},
        {text:"COLUMNA ERROR", value:'Columna'},
        {text:"DESCRIPCION ERROR", value:'Descripcion'},
        {text:"VALOR ERRADO", value:'Valor'},
        {text:"EJEMPLO ", value:'Ejemplo'},
      ],   
      enviado:false,
      error: "", 
      Errores:[],
      items: [
          {
            src:require('../assets/Bolsda_CNB.png'),
          }
        ],
  
  }),

    validations:{
      filesRethus:{required}
    },

    beforeMount() {        
      const Name = auth.getUserLogged();

       if(this.userLogged === undefined){
        store.commit('AsignarValorLogin', { ValorLogin: 0, user:'',tipousuario :'' });
      }else{
        store.commit('AsignarValorLogin', { ValorLogin: 1, user:Name.username,tipousuario :Name.tipousuario });
      }   
    },

    components: {
      FormAdministrator
    },

    methods:
    {

      
      UploadFile(){
        alert("mensaje")
      },


      UploadFileRethus(){
        this.enviado = true;
        const Er_TipoId = /^[a-zA-Z]{2}$/;
        const Er_NroId = /^[0-9]{1,15}$/;
        const Er_PNombrePapellido = /^[a-zA-ZáéíóúÁÉÍÓ ]{1,25}$/;
        const Er_SNombreSapellido = /^[a-zA-ZáéíóúÁÉÍÓÚ ]{0,25}$/;

        
       if(this.filesRethus !== "")
          {            
             readXlsFile(this.filesRethus).then((rows) =>{
            this.items = rows.filter((row, indice) => indice > 0);

            //Procesar el archivo
            for(let i=0; i< this.items.length; i++ )
            {
                if(Er_TipoId.exec(this.items[i][1]) === null)
                {
                    var Consecutivo = this.items[i][0];
                    var Columna = "Tipo Identificación";
                    var Descripcion = "Se permiten exactamente 2 letras, no números, no espacios, no caracteres especiales";
                    var Valor = this.items[i][1]
                    var Ejemplo = "CC , CE, PA"
                    this.Errores.push({Consecutivo,Columna,Descripcion,Valor,Ejemplo})
                    this.error = this.error + 1;
                }

                if(Er_NroId.exec(this.items[i][2]) === null)
                {
                    var Consecutivo = this.items[i][0];
                    var Columna = "Número Identificación";
                    var Descripcion = "Tiene letras, caracteres ilegales o supera 15 caracteres";
                    var Valor = this.items[i][2]
                    var Ejemplo = "99123456"
                    this.Errores.push({Consecutivo,Columna,Descripcion,Valor,Ejemplo})
                    this.error = this.error + 1;
                }

                if(Er_PNombrePapellido.exec(this.items[i][3]) === null)
                {
                    var Consecutivo = this.items[i][0];
                    var Columna = "Primer Apellido";
                    var Descripcion = "Tiene números, caracteres ilegales o supera 25 caracteres";
                    var Valor = this.items[i][3]
                    var Ejemplo = "Pérez"
                    this.Errores.push({Consecutivo,Columna,Descripcion,Valor,Ejemplo})
                    this.error = this.error + 1;
                } 
                
                if(Er_SNombreSapellido.exec(this.items[i][4]) === null)
                {
                    var Consecutivo = this.items[i][0];
                    var Columna = "Segundo Apellido";
                    var Descripcion = "Tiene números, caracteres ilegales o supera 25 caracteres";
                    var Valor = this.items[i][4]
                    var Ejemplo = "Pérez"
                    this.Errores.push({Consecutivo,Columna,Descripcion,Valor,Ejemplo})
                    this.error = this.error + 1;
                }   
                
                if(Er_PNombrePapellido.exec(this.items[i][5]) === null)
                {
                    var Consecutivo = this.items[i][0];
                    var Columna = "Primer Apellido";
                    var Descripcion = "Tiene números, caracteres ilegales o supera 25 caracteres";
                    var Valor = this.items[i][5]
                    var Ejemplo = "Castillo"
                    this.Errores.push({Consecutivo,Columna,Descripcion,Valor,Ejemplo})
                    this.error = this.error + 1;
                } 
                
                if(Er_SNombreSapellido.exec(this.items[i][6]) === null)
                {
                    var Consecutivo = this.items[i][0];
                    var Columna = "Segundo Apellido";
                    var Descripcion = "Tiene números, caracteres ilegales o supera 25 caracteres";
                    var Valor = this.items[i][6]
                    var Ejemplo = "GoméZ"
                    this.Errores.push({Consecutivo,Columna,Descripcion,Valor,Ejemplo})
                    this.error = this.error + 1;
                }                  


                if(this.error.length === 0){
                  this.error = 0;
                }
               
            } 
            
            }) 


              

          }
      },

      CleanFileRethus(){
       this.filesRethus = "";
       this.items = [];
       this.error= ""
      }



    },

    computed: 
      {
          userLogged() {
          return auth.getUserLogged();
          },

      ...mapState(['VarLogin','TipoUsuario'])
      }
  }
</script>
