<template>
    <div>
      <v-select
        v-model="selectedFilter"
        @change="onChange($event)"
        :items="filters"
        label="Filtrar por"
        outlined
      ></v-select>
      <!-- <v-date-picker v-model="selectedDate" label="Seleccionar fecha" outlined></v-date-picker> -->
  
      <!--        <v-col cols="auto">
          <v-btn  @click="getData" outlined elevation="8" size="x-large">Actualizar</v-btn>
        </v-col> -->
  
      <hr />
  
      <!--     <v-data-table
        :items-per-page="10"
        :headers="headers"
        :items="filteredData"
        :search="search"
      ></v-data-table> -->
  
      <template>
        <v-card>
          <v-card-title>
            {{ selectedFilter }}
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers2" :items="desserts" :search="search">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{ selectedFilter }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="800px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      New Item
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>
  
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.name"
                              label="Nombre"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.Nit"
                              label="Descripcion"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-select
                              v-model="editedItem.Estado"
                              :items="items"
                              label="Estado"
                              solo
                              width="500"
                            ></v-select>
                            <!--                         <v-text-field
                            v-model="editedItem.Estado"
                            label="Estado"                          
                          ></v-text-field> -->
                          </v-col>
                          <!--                       <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.descripcion"
                            label="Descripción"
                          ></v-text-field>
                        </v-col>
                         <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.protein"
                            label="Protein (g)"
                          ></v-text-field>
                        </v-col> -->
                        </v-row>
                      </v-container>
                    </v-card-text>
  
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close">
                        Cancelar
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="save">
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Are you sure you want to delete this item?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Cancel</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize"> Reset </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </template>
    </div>
  </template>
  
  <script>
  import jsonData from "@/Data/data.json";
  import auth from "@/auth";
  import axios from "axios";
  
  export default {
    data() {
      return {
        selectedFilter: "Empresas",
        selectedDate: null,
        filters: ["Empresas", "Ofertas"],
        /*       headers: [
          { text: "PERIODO", value: "month" },
          { text: "CANTIDAD POR PERIODO", value: "count" },
        ], */
        search: "",
        data: jsonData,
        DatosFiltros: [],
        dialog: false,
        dialogDelete: false,
        headers2: [
          {
            text: "NOMBRE",
            align: "start",
            sortable: false,
            value: "name",
          },
          { text: "DESCRIPCION", value: "Nit" },
          { text: "ESTADO", value: "Estado" },
          { text: "EMPRESA", value: "Empresa" },
          /*{ text: 'Protein (g)', value: 'protein' }, */
          { text: "ACCIONES", value: "actions", sortable: false },
        ],
        desserts: [],
        editedIndex: -1,
        editedItem: {
          id: 0,
          name: "",
          Nit: 0,
          Estado: false,
          NombreEmpresa: "",
        },
        defaultItem: {
          id: 0,
          name: "",
          Nit: 0,
          Estado: false,
          NombreEmpresa: "",
        },
        formTitle: "Actualización",
        items: ["Aprobado", "No Aprobado"],
      };
    },
  
    beforeMount() {
      this.getData();
    },
  
    computed: {
      filteredData() {
        return this.getMonthlyCount();
      },
  
      userLogged() {
        return auth.getUserLogged();
      },
    },
    methods: {
      onChange(event) {
        this.getData();
      },
  
      getMonthlyCount() {
        const monthlyCount = {};
        this.DatosFiltros.forEach((item) => {
          const date = new Date(item.created_at);
          const month = `Mes: ${
            date.getMonth() + 1
          } => Año: ${date.getFullYear()}`;
          if (monthlyCount[month]) {
            monthlyCount[month] += 1;
          } else {
            monthlyCount[month] = 1;
          }
        });
  
        return Object.keys(monthlyCount).map((month) => ({
          month,
          count: monthlyCount[month] + " " + this.selectedFilter,
        }));
      },
  
      async getData() {
        let url = "";
        this.DatosFiltros = [];
        this.desserts = [];
  
        if (this.selectedFilter === "Empresas") {
          url = process.env.VUE_APP_RUTA_API +"/empresas";
        }
        if (this.selectedFilter === "Ofertas") {
          url = process.env.VUE_APP_RUTA_API +"/ofertas";
        }
  
        try {
          //Se realiza la constulta al Api Rest dependiendo de la opcion seleccionada en la lista
          const Key = "Bearer " + auth.getUserLogged().token;
          let response = await axios.get(url, {
            headers: {
              Authorization: Key,
            },
          });
  
          if (response.data) {
            this.DatosFiltros = response.data;
  
            for (let i = 0; i <= this.DatosFiltros.length - 1; i++) {
              if (this.selectedFilter === "Empresas") {
                // console.log("empresa:",this.DatosFiltros)
                this.desserts.push({
                  id: this.DatosFiltros[i].id,
                  name: this.DatosFiltros[i].NombreEmpresa,
                  Nit: this.DatosFiltros[i].Nit,
                  Estado: this.DatosFiltros[i].Estado
                    ? "Aprobado"
                    : "No Aprobado",
                  Empresa: this.DatosFiltros[i].NombreEmpresa,
                });
              } else {
                //console.log("oferta:",this.DatosFiltros)
                this.desserts.push({
                  id: this.DatosFiltros[i].id,
                  name: this.DatosFiltros[i].NombreOferta,
                  Nit: this.DatosFiltros[i].DescripcionOferta,
                  Estado: this.DatosFiltros[i].Estado
                    ? "Aprobado"
                    : "No Aprobado",
                  Empresa: this.DatosFiltros[i].empresa.NombreEmpresa,
                });
              }
            }
          }
        } catch (error) {
          console.log("se presento el siguiente error:", error);
        }
      },
  
      //*********************************************** */
      initialize() {},
  
      editItem(item) {
        this.editedIndex = this.desserts.indexOf(item);
        this.editedItem = Object.assign({}, item);
        //console.log(this.editedItem)
        this.dialog = true;
      },
  
      /*     deleteItem(item) {
        this.editedIndex = this.desserts.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      }, 
  */
      deleteItemConfirm() {
        this.desserts.splice(this.editedIndex, 1);
        this.closeDelete();
      },
  
      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
  
      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
  
      save() {
        if (this.editedIndex > -1) {
          let url = "";
          if (this.selectedFilter === "Empresas") {
            url = process.env.VUE_APP_RUTA_API +"/empresas/";
          }
          if (this.selectedFilter === "Ofertas") {
            url = process.env.VUE_APP_RUTA_API +"/ofertas/";
          }
  
          const Key = "Bearer " + auth.getUserLogged().token;
          const EstGuardar = {
            Estado: this.editedItem.Estado === "No Aprobado" ? false : true,
          };
  
          Object.assign(this.desserts[this.editedIndex], this.editedItem);
          //Edicion del registro
  
          axios
            .put(url + this.editedItem.id, EstGuardar, {
              headers: {
                Authorization: Key,
              },
            })
            .then((response) => {
              //console.log(response.data)
              if (response.data) {
                console.log("Registro Editado con éxito");
                this.initialize();
              } else {
                console.log("Error al tratar de editar el registro");
              }
            })
            .catch(
              (e) =>
                function (error) {
                  console.log(error);
                }
            );
        } else {
          // console.log("sin -1")
          this.desserts.push(this.editedItem);
        }
        this.close();
      },
    },
  };
  </script>
  
  <style scoped>
  .wil {
    background: #f13210f5;
  }
  </style>
  